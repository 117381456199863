body {
  text-align: center;
  color: #fff;
  min-height: 100vh;
  -webkit-tap-highlight-color: transparent;
  background-color: #222;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0 1rem;
  font-family: Helvetica, "sans-serif";
  display: flex;
}

header {
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

main {
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  display: flex;
}

footer {
  color: gray;
}

h1 {
  margin-top: 0;
}

p {
  margin-top: 0;
  line-height: 1.75rem;
}

a {
  color: #fff;
}

a:hover {
  text-decoration: none;
}

@media (min-width: 50rem) {
  body {
    padding: 0 2rem;
    font-size: 1.25rem;
  }
}

/*# sourceMappingURL=index.cc8db6f3.css.map */
