body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: #222;
    font-family: 'Helvetica', 'sans-serif';
    margin: 0;
    padding: 0 1rem;
    min-height: 100vh;
    -webkit-tap-highlight-color: transparent;
}

header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

main {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;
}

footer {
    color: gray;
}

h1 {
    margin-top: 0;
}

p {
    margin-top: 0;
    line-height: 1.75rem;
}

a {
    color: white;
}

a:hover {
    text-decoration: none;
}

@media (min-width: 50rem) {
    body {
        padding: 0 2rem;
        font-size: 1.25rem;
    }
}
